import { getCourseDetails } from 'API/course'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { CourseDisplayStateActions } from 'redux/slices/CourseDisplayState'
import { PromiseType } from 'utility-types'

export const useGetCourseDetails = (_id: string) => {
	const [id, setId] = useState<string>()
	const [courses, setCourses] = useState<PromiseType<ReturnType<typeof getCourseDetails>>>()
	const [stable, setStable] = useState(false)

	const typeOfUser = useAppSelector((state) => state.CourseDisplayReducer.type)
	const type_of_course = useAppSelector((state) => state.CourseDisplayReducer.type_of_course)
	const viewAs = useAppSelector((state) => state.CourseDisplayReducer.viewAs)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (_id) {
			setId(_id)
			dispatch(CourseDisplayStateActions.setState({ course_id: _id }))
		}
	}, [_id])

	useEffect(() => {
		const run = async () => {
			if (id) {
				setCourses(await getCourseDetails(id))
			}
		}
		run()
	}, [id])

	useEffect(() => {
		if (courses) {
			console.log({ courses }, courses.map((elem) => elem.type).includes('Teacher'))
			if (courses.map((elem) => elem.type).includes('Teacher')) {
				dispatch(CourseDisplayStateActions.setTeacher())
				dispatch(CourseDisplayStateActions.setViewAs('Teacher'))
			} else {
				dispatch(CourseDisplayStateActions.setStudent())
				dispatch(CourseDisplayStateActions.setViewAs('Student'))
			}
			if (courses && courses[0]) {
				if (courses[0].course.type_of_course === 'Traditional') {
					dispatch(CourseDisplayStateActions.setCourseType('Traditional'))
				} else {
					dispatch(CourseDisplayStateActions.setCourseType('Online'))
				}
			}

			setStable(true)
		}
	}, [courses])

	const ShowDetails = (
		<>
			{courses && (
				<>
					<div className='px-2'>
						{typeOfUser === 'Teacher' && (
							<>
								<div className='row'>
									<div onClick={() => dispatch(CourseDisplayStateActions.toggle('Teacher'))} className='mx-4 btn btn-primary col-3'>
										View As Teacher
									</div>
									<br />
									<div onClick={() => dispatch(CourseDisplayStateActions.toggle('Student'))} className='mx-1 btn btn-primary col-3'>
										View As Student
									</div>
								</div>
								<div>Viewing as: {viewAs}</div>
							</>
						)}
						<h2 className='pt-2'>{courses?.[0]?.course.name}</h2>
						<div className='row'>
							<div className='col-lg-3 col-md-3 col-sm-6'>
								<h6>Institute : {courses?.[0]?.course.Institute}</h6>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-6'>
								<h6>Course Year :{courses?.[0]?.course.year}</h6>
							</div>
							<div className='col-lg-6 col-md-6 col-sm-6'>
								<h6>Description : {courses?.[0]?.course.description}</h6>
							</div>
						</div>
						<hr />
					</div>
				</>
			)}
		</>
	)

	return { id, setId, courses, ShowDetails, course: courses?.[0], viewAs, stable, type_of_course, setStable }
}
