import axios from 'axios'
import { CourseType } from 'types/CourseAdditionMetaData'
import { UserType } from 'types/UserAdditionMetaData'
import { getDefaultOptions } from './auth'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'

export const listCourses = () => {
	return handleApi<CourseType[]>(axios.get(getUrl('course/all'), getDefaultOptions()))
}

export const updateCourse = ({
	uid,
	name,
	description,
	Institute,
	year,
	Program,
	Semester,
	is_archived,
}: {
	uid: string
	name?: string
	description?: string
	is_archived?: boolean
	Institute?: string
	Program?: string
	Semester?: string
	year?: string
}) => {
	if (name) return handleApi<any>(axios.patch(getUrl(`course/update`), { uid, name }, getDefaultOptions()))
	if (description) return handleApi<any>(axios.patch(getUrl(`course/update`), { uid, description }, getDefaultOptions()))
	if (Institute) return handleApi<any>(axios.patch(getUrl(`course/update`), { uid, Institute }, getDefaultOptions()))
	if (year) return handleApi<any>(axios.patch(getUrl(`course/update`), { uid, year }, getDefaultOptions()))
	if (Program) return handleApi<any>(axios.patch(getUrl(`course/update`), { uid, Program }, getDefaultOptions()))
	if (Semester) return handleApi<any>(axios.patch(getUrl(`course/update`), { uid, Semester }, getDefaultOptions()))
	else return handleApi<any>(axios.patch(getUrl(`course/update`), { uid, is_archived }, getDefaultOptions()))
}

export const updateGroup = ({ name, new_name }: { new_name: string; name: string }) => {
	return handleApi<any>(axios.patch(getUrl(`course/group/update`), { name, new_name }, getDefaultOptions()))
}

export const courseById = (id: string) => {
	return handleApi<CourseType>(axios.get(getUrl(`course/byId/${id}`), getDefaultOptions()))
}

export const createSingleCourse = ({
	name,
	description,
	course_tag,
	end_date,
	type_of_course,
	start_date,
	year,
	Institute,
	Program,
	Semester,
}: {
	name: string
	description: string
	course_tag: string
	end_date: string
	type_of_course: string
	start_date: string
	year: string
	Institute: string
	Program: string
	Semester: string
}) => {
	return handleApi<{ failed: Array<any>; success: Array<any> }>(
		axios.post(
			getUrl(`course/create_single_course`),
			{ name, description, course_tag, end_date, start_date, type_of_course, year, Semester, Institute, Program },
			getDefaultOptions()
		)
	)
}

export const courseUsers = (id: string) => {
	return handleApi<{ teachers: Array<{ user: UserType }>; students: Array<{ user: UserType }> }>(
		axios.get(getUrl(`course/users/${id}`), getDefaultOptions())
	)
}

export const addGroupOfUsersAsStudents = (group_name: string, course_id: string) => {
	return handleApi<{ failed: Array<any>; success: Array<any> }>(
		axios.post(getUrl(`course/add_group/`), { course_id, group_name }, getDefaultOptions())
	)
}

export const removeGroupOfUsersAsStudents = (group_name: string, course_id: string) => {
	return handleApi<{ failed: Array<any>; success: Array<any> }>(
		axios.post(getUrl(`course/remove_group/`), { course_id, group_name }, getDefaultOptions())
	)
}

export const addGroupOfUsersAsTeachers = (group_name: string, course_id: string) => {
	return handleApi<{ failed: Array<any>; success: Array<any> }>(
		axios.post(getUrl(`course/add_group/`), { course_id, group_name, asTeacher: true }, getDefaultOptions())
	)
}

export const addTeacher = (teacher_id: string, course_id: string) => {
	return handleApi<UserType>(
		axios.post(
			getUrl(`course/add_teacher`),
			{
				teacher_id,
				course_id,
			},
			getDefaultOptions()
		)
	)
}

export const addStudent = (student_id: string, course_id: string) => {
	return handleApi<UserType>(
		axios.post(
			getUrl(`course/add_student`),
			{
				student_id,
				course_id,
			},
			getDefaultOptions()
		)
	)
}

export const removeStudent = (student_id: string, course_id: string) => {
	return handleApi<UserType>(
		axios.post(
			getUrl(`course/remove_student`),
			{
				student_id,
				course_id,
			},
			getDefaultOptions()
		)
	)
}

export const removeTeacher = (teacher_id: string, course_id: string) => {
	return handleApi<UserType>(
		axios.post(
			getUrl(`course/remove_teacher`),
			{
				teacher_id,
				course_id,
			},
			getDefaultOptions()
		)
	)
}

export const removeTeachers = (teacher_id: Array<string>, course_id: string) => {
	return handleApi<any>(axios.post(getUrl(`course/remove_group_teacher`), { teacher_id, course_id }, getDefaultOptions()))
}

export const myCourses = () => {
	return handleApi<{ teaching: Array<{ course: CourseType }>; studying: Array<{ course: CourseType }> }>(
		axios.get(getUrl(`course/myCourses`), getDefaultOptions())
	)
}

export const getCourseDetails = (course_id: string) => {
	return handleApi<Array<{ course: CourseType; type: 'Teacher' | 'Student'; current_module?: null | number }>>(
		axios.post(
			getUrl(`course/myCourseDetails`),
			{
				course_id,
			},
			getDefaultOptions()
		)
	)
}

export const getGrades = (course_id: string, asTeacher: boolean, limit: number, offset: number) => {
	if (asTeacher === true) {
		return handleApi(
			axios.get<any>(getUrl(`grades/all/?course_id=${course_id}&asTeacher=${asTeacher}&limit=${limit}&offset=${offset}`), getDefaultOptions())
		)
	}
	return handleApi(axios.get<any>(getUrl(`grades/?course_id=${course_id}&asTeacher=${asTeacher}&limit=${limit}`), getDefaultOptions()))
}

export const deleteCourse = (course_id: string) => {
	return handleApi(axios.delete<any>(getUrl(`course/?course_id=${course_id}`), getDefaultOptions()))
}
export const updateModuleDetails = (name: string, uid: string, index: number, course: string) => {
	return handleApi(axios.post(getUrl(`module/update_module`), { name, uid, index, course }, getDefaultOptions()))
}
export const getModules = (course_id: string) => {
	return handleApi(
		axios.get<
			{
				index: number
				uid: string
				name: string
				visible_from: string
				available_from: string
				availble_till: string
				passing_percentage: number
			}[]
		>(getUrl(`module/byCourse?course_id=${course_id}`))
	)
}
