// import { courseUsers } from 'API/course'
import { navigate, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React from 'react'
// import { UserType } from 'types/UserAdditionMetaData'

const People: React.FC<PageProps> = ({ params: { id: course_id, user_id } }) => {
	const { ShowDetails, viewAs, stable } = useGetCourseDetails(course_id)

	React.useEffect(() => {
		if (viewAs === 'Student' && stable) {
			navigate('/404')
		}
	}, [viewAs])

	React.useEffect(() => {
		console.log(user_id)
	}, [user_id])

	return <>{ShowDetails}</>
}

export default People
